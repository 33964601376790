<template>
  <div id="app">
    <Header />
    <main class="boxed__container">
      <p class="text-2xl font-bold mt-8 text-center text-orange-500">
        Our Services
      </p>
      <p class="text-lg text-center">
        MOJ Catering and Hospitality Management Institute is more than just a
        catering and hospitality management school. We also offer a variety of
        services to clients at the highest level of professionalism. See some of
        the services we offer
      </p>
      <div>
        <div class="flex flex-wrap">
          <div id="cake" class="w-full px-8 md:px-16 py-8">
            <p class="text-left font-semibold text-xl text-orange-500">
              Event Planning
            </p>
            <p class="text-left">
              It is time to make your dream event a reality without hassle and
              at affordable prices. We have a team of event planners, a reliable
              vendor database and the magic touch to make your dream event come
              alive.
            </p>
          </div>

          <div class="w-full px-8 md:px-16 py-8">
            <div>
              <div class="flex flex-wrap">
                <img
                  class="w-full md:w-1/4 h-64 object-cover p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1579557219/MOJ/49667090_2226508557617932_4064801540500619264_o.jpg"
                />
                <img
                  class="w-full md:w-1/4 h-64 object-fill p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1579557220/MOJ/79920885_2465838567018262_3261068212862713856_n.jpg"
                />

                <img
                  class="w-full md:w-1/4 h-64 object-cover p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1579557220/MOJ/79644734_2465840480351404_2206253618246451200_n.jpg"
                />

                <img
                  class="w-full md:w-1/4 h-64 object-cover p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1579557219/MOJ/50029883_2226508690951252_3019547104998588416_o.jpg"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap">
          <div id="cake" class="w-full px-8 md:px-16 py-8">
            <p class="text-left font-semibold text-xl text-orange-500">
              Theme, Venue & Budget Planning
            </p>
            <p class="text-left">
              We start by planning extensively with you in making sure we have
              your event theme, colors, venue, and budget is well estimated to
              cover your event. We anticipate all the unanticipated surprises to
              make sure nothing creeps up to extend your budget. We make
              compromise where it is necessary and finally, we produce a
              detailed Event plan that is ready for execution in line with your
              budget
            </p>
          </div>
        </div>

        <div class="flex flex-wrap">
          <div id="cake" class="w-full px-8 md:px-16 py-8">
            <p class="text-left font-semibold text-xl text-orange-500">
              We get the best vendors for the Job
            </p>
            <p class="text-left">
              We have an extensive list of reliable vendors that can implement
              even the most complex ideas and themes both locally and
              internationally. We will get our vendors in and ensure that all
              your event needs are delivered right on time.
            </p>
          </div>
        </div>

        <div class="flex flex-wrap">
          <div id="cake" class="w-full px-8 md:px-16 py-8">
            <p class="text-left font-semibold text-xl text-orange-500">
              We are masters of Catering
            </p>
            <p class="text-left">
              No matter what your event is, the right catering and cakes makes
              the difference. We at MOJ catering school and hospitality
              management institute are leaders in making sure that your event
              has the best meals served by the right people and managed properly
              to ensure that your guest has a great time to remember. With our
              expertise in cake making and sugar craft we can make your cake
              tell any type of story you desire at the most affordable prices.
            </p>
          </div>
        </div>

        <div class="flex flex-wrap">
          <div id="cake" class="w-full px-8 md:px-16 py-8">
            <p class="text-left font-semibold text-xl text-orange-500">
              We handle Everything, so you can have a Wonderful Event
            </p>
            <p class="text-left">
              Because it is your event, we want to make sure that we handle
              everything, while you have a special day. So on the deal day we
              commence the execution of your planned event, from venue
              decoration to catering, logistics, photography, ceremony
              management, security and all the key ingredient that your event
              needs
            </p>
          </div>
        </div>

        <div class="flex flex-wrap">
          <div id="cake" class="w-full px-8 md:px-16 py-8">
            <p class="text-left font-semibold text-xl text-orange-500">
              How to Get Started
            </p>
            <p class="text-left">
              We are more than delighted to assist you in planning your event.
              You can contact us today by giving us a call on this numbers
              ------------ or filling out the form below and one of our planners
              will reach out to you to commence the process. We look forward to
              working with you.
            </p>
          </div>
        </div>

        <div class="flex flex-wrap">
          <div id="cake" class="w-full px-8 md:px-16 py-8">
            <p class="text-left font-semibold text-xl text-orange-500">
              Food Services
            </p>
            <p class="text-left">
              The best catering makes your event special and keeps your guests
              happy and relaxed. Whatever event you are planning either a
              birthday, wedding, promotion, child dedication, anniversary, or
              professional meetings, MOJ catering can offer food services for
              your event to meet your budget. We cover both indoors and outdoor
              events, preparing food for small and large gatherings either as
              served meals, buffet, or packaged food in well designed and
              elegant packs.
            </p>

            <p class="text-left">
              MOJ catering is the leading provider of food services for all
              types of events in Nigeria. We start by seating with you and
              understanding your budget, what variety and combination of food
              suites the theme of your event and your budget, we plan for full
              course meals with special appetizers, finger foods and deserts to
              go with your main course.
            </p>

            <p class="text-left">
              We make sure that we get the best of ingredients from the right
              vendors and sellers, we setup the catering space either at a place
              you have designated or at our location and we prepare local and
              intercontinental meals that gives your guests the best experience
              at your event.
            </p>
          </div>

          <div class="w-full px-8 md:px-16 py-8">
            <div>
              <div class="flex flex-wrap">
                <img
                  class="w-full md:w-1/4 h-64 object-cover p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1687290982/WhatsApp_Image_2023-06-20_at_20.54.27_1.jpg"
                />
                <img
                  class="w-full md:w-1/4 h-64 object-fill p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1651476795/MOJ/WhatsApp_Image_2022-04-16_at_6.28.30_PM_3.jpg"
                />
                <img
                  class="w-full md:w-1/4 h-64 object-cover p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1651476796/MOJ/WhatsApp_Image_2022-04-16_at_6.28.31_PM_3.jpg"
                />
                <img
                  class="w-full md:w-1/4 h-64 object-fill p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1651476796/MOJ/WhatsApp_Image_2022-04-16_at_6.28.32_PM_2.jpg"
                />
                <img
                  class="w-full md:w-1/4 h-64 object-fill p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1687290983/WhatsApp_Image_2023-06-20_at_20.54.29_1.jpg"
                />
                <img
                  class="w-full md:w-1/4 h-64 object-cover p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1651476796/MOJ/WhatsApp_Image_2022-04-16_at_6.28.32_PM.jpg"
                />

                <img
                  class="w-full md:w-1/4 h-64 object-cover p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1651476795/MOJ/WhatsApp_Image_2022-04-16_at_6.28.29_PM_3.jpg"
                />

                <img
                  class="w-full md:w-1/4 h-64 object-cover p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1687290983/WhatsApp_Image_2023-06-20_at_20.54.29_1.jpg"
                />

                <img
                  class="w-full md:w-1/4 h-64 object-cover p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1651476794/MOJ/WhatsApp_Image_2022-04-16_at_6.28.29_PM_1.jpg"
                />
                <img
                  class="w-full md:w-1/4 h-64 object-fill p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1651476794/MOJ/WhatsApp_Image_2022-04-16_at_6.28.28_PM_2.jpg"
                />

                <img
                  class="w-full md:w-1/4 h-64 object-cover p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1651476794/MOJ/WhatsApp_Image_2022-04-16_at_6.28.28_PM_1.jpg"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap">
          <div id="cake" class="w-full px-8 md:px-16 py-8">
            <p class="text-left font-semibold text-xl text-orange-500">
              Hotel & Restaurant Management
            </p>
            <p class="text-left">
              Do you have a hotel or restaurant business that is not doing too
              well or a new business that you need expert touch in setting it up
              right, MOJ catering provides a service were we partner with you
              and ensure that you setup and continue to run a profitable
              culinary and hospitality business.
            </p>

            <p class="text-left">
              We commence this journey with you by understanding your business
              vision and what you hope to achieve with your hotel or your
              restaurant, then we set reality targets and put our expertise to
              work to ensure that you continuously meet those targets to remain
              profitable.
            </p>

            <p class="text-left">
              For your hotel, we can help with the interior decoration of your
              hotel rooms under very stringent budget that will wow your guests
              and improve your revenue generation. We can help you in hotel
              staffing, getting the right vendors to implement certain of your
              goals and making sure they deliver on tasks appropriately, train
              your staff on topics such as customer relationship management and
              general hospitality management that would help them to
              continuously retain customers and reduce possibilities of
              attrition and finally provide you with consistent advisory to help
              you stay profitable.
            </p>

            <p class="text-left">
              For your restaurant we can help you in setting up your restaurant
              from start to finish. MOJ catering are leaders in the food
              business, and we understand what it means to get your restaurant
              to uphold great catering standards and manage customer
              relationships. We can help with the design and setting up of your
              restaurant, design your restaurant menu and train your chef team
              on how to prepare these meals using special tips and tricks. Also,
              we can help you with a failing restaurant business and get you to
              remain profitable again. We do this by partnering with you and
              identifying various gaps and shortcomings that might have
              inhibited the growth of your culinary business. Then we set
              realistic goals and implement all that needs to be in place to
              achieve those goals for your restaurant business.
            </p>

            <p class="text-left">
              So how do you get started? Contact us today by filling the form
              below and telling us about your hotel and restaurant business,
              your current state and what you hope to achieve. We will contact
              you immediately we receive your request. Also, you can contact us
              by calling any of our phone numbers in the contact us pages
            </p>

            <div>
              <div class="flex flex-wrap">
                <img
                  class="w-full md:w-1/4 h-64 object-cover p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1655070966/MOJ/WhatsApp_Image_2022-06-12_at_10.46.41_PM.jpg"
                />
                <img
                  class="w-full md:w-1/4 h-64 object-fill p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1655070966/MOJ/WhatsApp_Image_2022-06-12_at_10.46.39_PM.jpg"
                />

                <img
                  class="w-full md:w-1/4 h-64 object-cover p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1655070966/MOJ/WhatsApp_Image_2022-06-12_at_10.46.40_PM.jpg"
                />
                <img
                  class="w-full md:w-1/4 h-64 object-fill p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1655070966/MOJ/WhatsApp_Image_2022-06-12_at_10.46.39_PM_1.jpg"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap">
          <div id="cake" class="w-full px-8 md:px-16 py-8">
            <p class="text-left font-semibold text-xl text-orange-500">
              General Consultancy
            </p>
            <p class="text-left">
              The culinary and hospitality industry is a broad business with a
              host of moving parts and difficulties and if you have a business
              in this industry then you understand that it can be difficult to
              understand why your business is not hit the targets you have set.
              We at MOJ Catering can assist you with any area of your culinary
              and hospitality business.
            </p>

            <p class="text-left">
              So how do you get started? Contact us today by filling the form
              below and telling us about your hotel and restaurant business,
              your current state and what you hope to achieve. We will contact
              you immediately we receive your request. Also, you can contact us
              by calling any of our phone numbers in the contact us pages
            </p>
          </div>
        </div>

        <div class="flex flex-wrap">
          <div id="cake" class="w-full px-8 md:px-16 py-8">
            <p class="text-left font-semibold text-xl text-orange-500">
              Cakes & Baking
            </p>
            <p class="text-left">
              Cakes tell a lot about what you are celebrating and for the
              special moment, you need a special cake. MOJ catering and
              hospitality management institutes are leaders in making cakes that
              means something and drives home the theme of that special moment
              you are celebrating.
            </p>

            <p class="text-left">
              We make small and large cakes for birthdays, weddings,
              anniversaries, corporate events that represents something unique,
              whatever you have in mind, we can make it into a yummy tasting
              cake that will wow your guest.
            </p>

            <p class="text-left">
              We have experts in Sugar crafts that can design any object with a
              cake and include lightings, animations, and any other effect you
              will need your cake to have. So, when next you are shopping for a
              cake that will wow your audience and look nothing like a cake but
              everything like a creation, contact us at MOJ catering and we will
              make your dream cake.
            </p>
          </div>
          <div class="w-full px-8 md:px-16 py-8">
            <div>
              <div class="flex flex-wrap">
                <img
                  class="w-full md:w-1/4 h-64 object-cover p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/w_1000,ar_1:1,c_fill,g_auto/v1579387220/MOJ/WhatsApp_Image_2020-01-14_at_18.56.21_1.jpg"
                />
                <img
                  class="w-full md:w-1/4 h-64 object-fill p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1687291980/WhatsApp_Image_2023-06-20_at_21.07.17_1.jpg"
                />

                <img
                  class="w-full md:w-1/4 h-64 object-cover p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/w_1000,ar_1:1,c_fill,g_auto/v1579449138/MOJ/WhatsApp_Image_2020-01-14_at_18.56.12.jpg"
                />
                <img
                  class="w-full md:w-1/4 h-64 object-fill p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1687291980/WhatsApp_Image_2023-06-20_at_21.07.18_1.jpg"
                />

                <img
                  class="w-full md:w-1/4 h-64 object-cover p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1687291979/WhatsApp_Image_2023-06-20_at_21.07.17.jpg"
                />
                <img
                  class="w-full md:w-1/4 h-64 object-fill p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1579449136/MOJ/WhatsApp_Image_2020-01-14_at_18.56.16.jpg"
                />

                <img
                  class="w-full md:w-1/4 h-64 object-cover p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1579449134/MOJ/WhatsApp_Image_2020-01-14_at_18.56.19.jpg"
                />
                <img
                  class="w-full md:w-1/4 h-64 object-fill p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1687291979/WhatsApp_Image_2023-06-20_at_21.07.18.jpg"
                />

                <img
                  class="w-full md:w-1/4 h-64 object-cover p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1579449130/MOJ/WhatsApp_Image_2020-01-14_at_18.56.22.jpg"
                />
                <img
                  class="w-full md:w-1/4 h-64 object-fill p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1579449137/MOJ/WhatsApp_Image_2020-01-14_at_18.56.14_1.jpg"
                />
                <img
                  class="w-full md:w-1/4 h-64 object-cover p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1687291978/WhatsApp_Image_2023-06-20_at_21.07.19.jpg"
                />
                <img
                  class="w-full md:w-1/4 h-64 object-fill p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1687291978/WhatsApp_Image_2023-06-20_at_21.07.19_1.jpg"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- 
        <div class="flex flex-wrap">
          <div id="cake" class="w-full px-8 md:px-16 py-8">
            <p class="text-left font-semibold text-xl text-orange-500">
              Cake Decoration Class
            </p>
            <p class="text-left">
              Cake, in general are any of a variety of breads, shortened or
              unshortened, usually shaped by the tin in which it is baked; more
              specifically, a sweetened bread, often rich or delicate.
            </p>

            <p class="text-left">
              The most commonly used cake ingredients include flour, sugar,
              eggs, butter or oil or margarine, a liquid, and leavening agents,
              such as baking soda or baking powder.
            </p>

            <p class="text-left">
              Cake is often served as a celebratory dish on ceremonial
              occasions, such as weddings, anniversaries, and birthdays or can
              be enjoyed as a simple meal
            </p>
            <br />

            <p class="text-left">
              Join MOJ INSTITUTE OF CATERING & HOTEL MANAGEMENT to learn more
              about Cake Making and Decoration
            </p>
          </div>
          <div class="w-full px-8 md:px-16 py-8">
            <div>
              <div class="flex flex-wrap">
                <img
                  class="w-full md:w-1/4 h-64 object-cover p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/w_1000,ar_1:1,c_fill,g_auto/v1579387220/MOJ/WhatsApp_Image_2020-01-14_at_18.56.21_1.jpg"
                />
                <img
                  class="w-full md:w-1/4 h-64 object-fill p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/w_1000,ar_1:1,c_fill,g_auto/v1579387220/MOJ/WhatsApp_Image_2020-01-14_at_18.56.20.jpg"
                />

                <img
                  class="w-full md:w-1/4 h-64 object-cover p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/w_1000,ar_1:1,c_fill,g_auto/v1579449138/MOJ/WhatsApp_Image_2020-01-14_at_18.56.12.jpg"
                />
                <img
                  class="w-full md:w-1/4 h-64 object-fill p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1579449133/MOJ/WhatsApp_Image_2020-01-14_at_18.56.18_1.jpg"
                />

                <img
                  class="w-full md:w-1/4 h-64 object-cover p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1579449140/MOJ/WhatsApp_Image_2020-01-14_at_18.56.11.jpg"
                />
                <img
                  class="w-full md:w-1/4 h-64 object-fill p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1579449136/MOJ/WhatsApp_Image_2020-01-14_at_18.56.16.jpg"
                />

                <img
                  class="w-full md:w-1/4 h-64 object-cover p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1579449134/MOJ/WhatsApp_Image_2020-01-14_at_18.56.19.jpg"
                />
                <img
                  class="w-full md:w-1/4 h-64 object-fill p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1579449132/MOJ/WhatsApp_Image_2020-01-14_at_18.56.20_1.jpg"
                />

                <img
                  class="w-full md:w-1/4 h-64 object-cover p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1579449130/MOJ/WhatsApp_Image_2020-01-14_at_18.56.22.jpg"
                />
                <img
                  class="w-full md:w-1/4 h-64 object-fill p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1579449137/MOJ/WhatsApp_Image_2020-01-14_at_18.56.14_1.jpg"
                />
                <img
                  class="w-full md:w-1/4 h-64 object-cover p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1611875788/MOJ/WhatsApp_Image_2021-01-12_at_3.46.56_PM_1.jpg"
                />
                <img
                  class="w-full md:w-1/4 h-64 object-fill p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1611875788/MOJ/WhatsApp_Image_2021-01-12_at_3.46.56_PM_2.jpg"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap">
          <div class="w-full px-8 md:px-16 py-8" id="pastry">
            <p class="text-left font-semibold text-xl text-orange-500">
              Pastry Class
            </p>
            <p class="text-left">
              Pastry is a dough of flour, water and shortening that may be
              savoury or sweetened. Sweetened pastries are often described as
              bakers' confectionery. The word "pastries" suggests many kinds of
              baked products made from ingredients such as flour, sugar, milk,
              butter, shortening, baking powder, and eggs.
            </p>
            <br />

            <p class="text-left">
              Join MOJ INSTITUTE OF CATERING & HOTEL MANAGEMENT to learn more
              about Pastry Making
            </p>
          </div>
          <div class="w-full px-8 md:px-16 py-8">
            <div>
              <div class="flex flex-wrap">
                <img
                  class="w-full md:w-1/4 h-64 object-cover p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1579557223/MOJ/80302108_2465838410351611_1635556664809619456_n.jpg"
                />
                <img
                  class="w-full md:w-1/4 h-64 object-fill p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1579557219/MOJ/49804353_2226506550951466_4168992623853305856_o.jpg"
                />

                <img
                  class="w-full md:w-1/4 h-64 object-cover p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1579557219/MOJ/49793725_2226508340951287_3061417414022397952_o.jpg"
                />
                <img
                  class="w-full md:w-1/4 h-64 object-fill p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1579557219/MOJ/49324220_2226507734284681_8826845301579972608_o.jpg"
                />

                <img
                  class="w-full md:w-1/4 h-64 object-cover p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1579449143/MOJ/WhatsApp_Image_2020-01-14_at_18.55.49_2.jpg"
                />
                <img
                  class="w-full md:w-1/4 h-64 object-fill p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1579449140/MOJ/WhatsApp_Image_2020-01-14_at_18.55.52_1.jpg"
                />

                <img
                  class="w-full md:w-1/4 h-64 object-cover p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1579449143/MOJ/WhatsApp_Image_2020-01-14_at_18.55.49_1.jpg"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap">
          <div class="w-full px-8 md:px-16 py-8" id="event">
            <p class="text-left font-semibold text-xl text-orange-500">
              Event Decoration Class
            </p>
            <p class="text-left">
              Decorating event halls is the art of transforming a reception
              venue into something that was only imagined, by individuals. It is
              the end result of painstaking effort to creatively beautifying a
              place, from wedding receptions and conferences seminars to funeral
              services, etc.
            </p>
            <br />
            <p class="text-left">
              Join MOJ INSTITUTE OF CATERING & HOTEL MANAGEMENT to learn more
              about Event Decoration
            </p>
          </div>
          <div class="w-full px-8 md:px-16 py-8">
            <div>
              <div class="flex flex-wrap">
                <img
                  class="w-full md:w-1/4 h-64 object-cover p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1579449142/MOJ/WhatsApp_Image_2020-01-14_at_18.55.50.jpg"
                />
                <img
                  class="w-full md:w-1/4 h-64 object-fill p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1579449142/MOJ/WhatsApp_Image_2020-01-14_at_18.55.50_1.jpg"
                />

                <img
                  class="w-full md:w-1/4 h-64 object-cover p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1579449140/MOJ/WhatsApp_Image_2020-01-14_at_18.55.52.jpg"
                />
                <img
                  class="w-full md:w-1/4 h-64 object-fill p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1579557220/MOJ/79920885_2465838567018262_3261068212862713856_n.jpg"
                />

                <img
                  class="w-full md:w-1/4 h-64 object-cover p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1579557220/MOJ/79644734_2465840480351404_2206253618246451200_n.jpg"
                />
                <img
                  class="w-full md:w-1/4 h-64 object-fill p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1579557219/MOJ/49667090_2226508557617932_4064801540500619264_o.jpg"
                />

                <img
                  class="w-full md:w-1/4 h-64 object-cover p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1579557219/MOJ/50029883_2226508690951252_3019547104998588416_o.jpg"
                />

                <img
                  class="w-full md:w-1/4 h-64 object-cover p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1579701408/MOJ/WhatsApp_Image_2020-01-22_at_14.36.13.jpg"
                />

                <img
                  class="w-full md:w-1/4 h-64 object-cover p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1579701408/MOJ/WhatsApp_Image_2020-01-22_at_14.36.13_1.jpg"
                />

                <img
                  class="w-full md:w-1/4 h-64 object-cover p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1579561954/MOJ/event-decoration-roses-columns-260nw-518257003.webp"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap">
          <div class="w-full px-8 md:px-16 py-8" id="pastry">
            <p class="text-left font-semibold text-xl text-orange-500">
              Sugar Craft Class
            </p>
            <p class="text-left">
              Sugars are a source of energy for the body. During intense
              physical activity, they are the main energy source.
            </p>

            <p class="text-left">
              Sugars are carbohydrates, which serve as the main energy source
              for the body. There are many types of sugars. They occur both
              naturally and as ingredients in many foods. The most familiar
              sugar is sucrose. It is made of two simple sugars, fructose and
              glucose. Fruits and vegetables naturally contain fructose and
              glucose.
            </p>
            <br />

            <p class="text-left">
              Join MOJ INSTITUTE OF CATERING & HOTEL MANAGEMENT to learn more
              about Sugar Craft Making
            </p>
          </div>
          <div class="w-full px-8 md:px-16 py-8">
            <div>
              <div class="flex flex-wrap">
                <img
                  class="w-full md:w-1/3 h-64 object-cover p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1579680775/MOJ/WhatsApp_Image_2020-01-21_at_20.24.49.jpg"
                />
                <img
                  class="w-full md:w-1/3 h-64 object-fill p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1579680774/MOJ/WhatsApp_Image_2020-01-21_at_20.26.13.jpg"
                />

                <img
                  class="w-full md:w-1/3 h-64 object-cover p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1579680774/MOJ/WhatsApp_Image_2020-01-21_at_20.26.14.jpg"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap">
          <div class="w-full px-8 md:px-16 py-8" id="pastry">
            <p class="text-left font-semibold text-xl text-orange-500">
              Cocktail Class
            </p>
            <p class="text-left">
              There's a drink for every mood or occasion. Find your favorite
              cocktail recipes below. Or think outside the glass and try a brand
              new drink. Your new favorite could be a shake or stir away.
            </p>

            <p class="text-left">
              A cocktail is a mixed drink, which is either a combination of
              drinks, or one or more drinks mixed with other ingredients such as
              fruit juice, flavored syrup, or cream. There are various types of
              cocktails, based on the number and kind of ingredients added.
            </p>
            <br />

            <p class="text-left">
              Join MOJ INSTITUTE OF CATERING & HOTEL MANAGEMENT to learn more
              about Cocktail Class
            </p>
          </div>
          <div class="w-full px-8 md:px-16 py-8">
            <div>
              <div class="flex flex-wrap">
                <img
                  class="w-full md:w-1/3 h-64 object-fill p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1579680774/MOJ/WhatsApp_Image_2020-01-21_at_20.26.27.jpg"
                />
                <img
                  class="w-full md:w-1/3 h-64 object-fill p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1579680774/MOJ/WhatsApp_Image_2020-01-21_at_20.26.28.jpg"
                />

                <img
                  class="w-full md:w-1/3 h-64 object-fill p-1"
                  src="https://res.cloudinary.com/dldd8ucby/image/upload/v1611875784/MOJ/WhatsApp_Image_2021-01-12_at_4.04.44_PM.jpg"
                />
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header2.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
  metaInfo: {
    title: "Services",
  },
};
</script>

<style></style>
