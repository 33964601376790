<template>
  <div id="team" class="py-16">
    <div class="flex flex-wrap px-8 md:px-16 py-4">
      <div class="w-full md:w-1/3 my-6 mx-auto">
        <img
          class="rounded-full p-4 h-64 w-64 object-fill mx-auto"
          src="https://res.cloudinary.com/dldd8ucby/image/upload/c_crop,g_face,h_400,w_400/v1687289918/1686930509088.jpg"
        />
        <p>Rev'd Emmanuel A Ojo</p>
        <p class="text-orange-500">B.A. M.A (Manchester) PhD</p>
      </div>

      <div class="w-full md:w-1/3 my-6">
        <img
          class="rounded-full p-4 h-64 w-64 object-fill mx-auto"
          src="https://res.cloudinary.com/dldd8ucby/image/upload/c_crop,g_face,h_400,w_400/v1687289916/1686930509064.jpg"
        />
        <p>Mrs Modupe Joy Ojo</p>
        <p class="text-orange-500">
          HND Food Science Tech, PGD Science Management
        </p>
      </div>

      <!-- <div class="w-full md:w-1/3 my-6">
        <img
          class="rounded-full p-4 h-64 w-64 object-fill mx-auto"
          src="https://res.cloudinary.com/dldd8ucby/image/upload/v1696056034/Mrs_Olawoye_Francisca.jpg"
        />
        <p>Mrs Olawoye Francisca</p>
        <p class="text-orange-500">Senior Instructor</p>
      </div>

      <div class="w-full md:w-1/3 my-6">
        <img
          class="rounded-full p-4 h-64 w-64 object-fill mx-auto"
          src="https://res.cloudinary.com/dldd8ucby/image/upload/v1651476908/MOJ/WhatsApp_Image_2022-04-16_at_6.33.15_PM.jpg"
        />
        <p>A. Ndukeabasi Uwem William Favour</p>
        <p class="text-orange-500">Diploma in Hotel and Catering Management</p>
      </div> -->

      <div class="w-full md:w-1/3 my-6">
        <img
          class="rounded-full p-4 h-64 w-64 object-fill mx-auto"
          src="https://res.cloudinary.com/dldd8ucby/image/upload/v1651476908/MOJ/WhatsApp_Image_2022-04-16_at_6.36.34_PM.jpg"
        />
        <p>Mrs Omolara Fakande</p>
        <p class="text-orange-500">B.Tech Food Science Technology</p>
      </div>

      <div class="w-full md:w-1/3 my-6">
        <img
          class="rounded-full p-4 h-64 w-64 object-fill mx-auto"
          src="https://res.cloudinary.com/dldd8ucby/image/upload/c_crop,g_face,h_600,w_600/v1717661071/MOJ/WhatsApp_Image_2024-06-01_at_19.46.32.jpg"
        />
        <p>Abosede Ojemen Cecilia</p>
        <p class="text-orange-500">NCE(Acct Double Major) (Trade test)</p>
        <p class="text-orange-500">Adminstrator</p>
      </div>

      <div class="w-full md:w-1/3 my-6">
        <img
          class="rounded-full p-4 h-64 w-64 object-fill mx-auto"
          src="https://res.cloudinary.com/dldd8ucby/image/upload/c_crop,g_face,h_500,w_500/v1717661193/MOJ/WhatsApp_Image_2024-06-01_at_19.59.22.jpg"
        />
        <p>Miriam Ederiene</p>
        <p class="text-orange-500">Dip. In Catering</p>
        <p class="text-orange-500">Tutor</p>
      </div>
      <div class="w-full md:w-1/3 my-6">
        <img
          class="rounded-full p-4 h-64 w-64 object-fill mx-auto"
          src="https://res.cloudinary.com/dldd8ucby/image/upload/c_thumb,g_face,h_250,w_250/v1717661647/MOJ/WhatsApp_Image_2024-06-01_at_20.03.54.jpg"
        />
        <p>Mrs Folasade Oyediran</p>
        <p class="text-orange-500">HND, PGD, Bsc Hospitality Management</p>
        <p class="text-orange-500">
          Professional qualification Full Member NHCI
        </p>
      </div>

      <div class="w-full md:w-1/3 my-6">
        <img
          class="rounded-full p-4 h-64 w-64 object-fill mx-auto"
          src="https://res.cloudinary.com/dldd8ucby/image/upload/c_crop,g_face,h_400,w_400/v1717661824/MOJ/WhatsApp_Image_2024-06-01_at_20.24.37.jpg"
        />
        <p>Tife Janet Ojo</p>
        <p class="text-orange-500"></p>
        <p class="text-orange-500">Social Media Strategist/ Finance Director</p>
      </div>

      <div class="w-full md:w-1/3 my-6">
        <img
          class="rounded-full p-4 h-64 w-64 object-fill mx-auto"
          src="https://res.cloudinary.com/dldd8ucby/image/upload/v1717662290/MOJ/WhatsApp_Image_2024-06-02_at_14.10.20.jpg"
        />
        <p>Obiunu Hannah Enifom</p>
        <p class="text-orange-500">Dip. In Catering</p>
        <p class="text-orange-500">Tutor</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style scoped>
div {
  text-align: center;
}
</style>
