<template>
  <div>
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2  px-8 md:px-16 py-8">
        <div>
          <div class="flex flex-wrap">
            <img
              class="w-full md:w-1/2 h-32 object-cover p-1"
              src="https://res.cloudinary.com/dldd8ucby/image/upload/v1579449143/MOJ/WhatsApp_Image_2020-01-14_at_18.55.49_2.jpg"
            />
            <img
              class="w-full md:w-1/2 h-32 object-cover p-1"
              src="https://res.cloudinary.com/dldd8ucby/image/upload/w_1000,ar_1:1,c_fill,g_auto/v1579449143/MOJ/WhatsApp_Image_2020-01-14_at_18.55.49_1.jpg"
            />
          </div>
          <div class="flex flex-wrap">
            <img
              class="w-full md:w-1/2 h-32 object-cover p-1"
              src="https://res.cloudinary.com/dldd8ucby/image/upload/w_1000,ar_1:1,c_fill,g_auto/v1579449144/MOJ/WhatsApp_Image_2020-01-14_at_18.55.49.jpg"
            />
            <img
              class="w-full md:w-1/2 h-32 object-fill p-1"
              src="https://res.cloudinary.com/dldd8ucby/image/upload/w_1000,ar_1:1,c_fill,g_auto/v1579449145/MOJ/WhatsApp_Image_2020-01-14_at_18.55.48.jpg"
            />
          </div>
        </div>
      </div>
      <div
        class="w-full md:w-1/2  px-8 md:px-16 py-16 order-first md:order-last"
      >
        <p class="text-left font-semibold text-xl text-orange-500">
          Pastry Class
        </p>
        <p class="text-left">
          Pastry is a dough of flour, water and shortening that may be savoury
          or sweetened. Sweetened pastries are often described as bakers'
          confectionery. The word "pastries" suggests many kinds of baked
          products made from ingredients such as flour, sugar, milk, butter,
          shortening, baking powder, and eggs.
        </p>
        <br />

        <p class="text-left">
          Join MOJ INSTITUTE OF CATERING & HOTEL MANAGEMENT to learn more about
          Pastry Making
        </p>

        <router-link
          to="/services#pastry"
          class="bg-orange-500 text-white p-2 m-2 rounded float-left"
          >Read More</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {}
};
</script>

<style scoped>
div {
  text-align: center;
}
</style>
