<template>
  <div id="app">
    <Landing />
    <About class="boxed__container" />
    <main>
      <div class="w-full px-8 md:px-16 py-8">
        <div>
          <div class="flex flex-wrap">
            <img
              class="image-height w-full md:w-1/4 object-cover p-1"
              src="https://res.cloudinary.com/dldd8ucby/image/upload/w_1000,ar_1:1,c_fill,g_auto/v1611875787/MOJ/WhatsApp_Image_2021-01-12_at_3.46.57_PM_1.jpg"
            />

            <img
              class="image-height w-full md:w-1/4 object-fill p-1"
              src="https://res.cloudinary.com/dldd8ucby/image/upload/w_1000,ar_1:1,c_fill,g_auto/v1611875788/MOJ/WhatsApp_Image_2021-01-12_at_3.46.57_PM.jpg"
            />

            <img
              class="image-height w-full md:w-1/4 object-fill p-1"
              src="https://res.cloudinary.com/dldd8ucby/image/upload/w_1000,ar_1:1,c_fill,g_auto/v1579557223/MOJ/80302108_2465838410351611_1635556664809619456_n.jpg"
            />

            <img
              class="image-height w-full md:w-1/4 object-fill p-1"
              src="https://res.cloudinary.com/dldd8ucby/image/upload/w_1000,ar_1:1,c_fill,g_auto/v1611875787/MOJ/WhatsApp_Image_2021-01-12_at_3.46.58_PM.jpg"
            />
          </div>
        </div>
      </div>
    </main>
    <Services />

    <Footer />
  </div>
</template>

<script>
import Landing from "@/components/Landing.vue";
import About from "@/components/About.vue";
import Services from "@/components/Services.vue";

import Footer from "@/components/Footer.vue";

export default {
  metaInfo: {
    title: "Home",
  },
  components: {
    Landing,
    About,
    Services,
    Footer,
  },
};
</script>

<style>
.image-height {
  height: 25rem;
}
</style>
