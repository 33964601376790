<template>
  <div class="bg-gray-200 py-8">
    <main class="boxed__container">
      <p class="text-2xl font-bold text-orange-500">Our Services</p>
      <p class="text-lg">
        Here is a breakdown of all the services we offer here at MOJ
      </p>
      <CakeDecoration />
      <Pastry />
      <EventDecoration />
      <router-link
        to="/services"
        class="bg-orange-500 text-white p-4 mx-auto my-8 rounded"
        >View our other services</router-link
      >
    </main>
  </div>
</template>

<script>
import CakeDecoration from "@/components/CakeDecoration.vue";
import Pastry from "@/components/Pastry.vue";
import EventDecoration from "@/components/EventDecoration.vue";
export default {
  components: {
    CakeDecoration,
    Pastry,
    EventDecoration,
  },
};
</script>

<style scoped>
div {
  text-align: center;
}
</style>
