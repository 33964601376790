<template>
  <div class="font-sans antialiased w-full" id="app">
    <nav class="bg-orange-500">
      <main
        class="
          boxed__container
          flex
          items-center
          text-white
          justify-between
          flex-wrap
          p-6
        "
      >
        <div class="flex items-center flex-no-shrink text-white mr-6">
          <router-link to="/">
            <div class="flex items-center flex-no-shrink text-black mr-6">
              <img
                class="inline m-1"
                src="https://res.cloudinary.com/dldd8ucby/image/upload/v1611939356/MOJ/moj_logo_no_bg.png"
              />
              <span
                class="
                  font-semibold
                  text-lg text-white
                  md:px-4 md:py-2
                  lg:mt-0
                  mr-4
                "
                >Moj Catering</span
              >
            </div>
          </router-link>
        </div>
        <div class="block sm:hidden">
          <button
            @click="toggle"
            class="
              flex
              items-center
              px-3
              py-2
              border
              rounded
              text-teal-lighter
              border-teal-light
            "
          >
            <svg
              class="fill-current h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        <div
          :class="open ? 'block' : 'hidden'"
          class="w-full flex-grow sm:flex sm:items-center sm:w-auto"
        >
          <div class="text-sm sm:flex-grow"></div>
          <div>
            <p
              class="
                block
                mt-4
                lg:inline-block
                uppercase
                font-semibold
                text-lg
                md:px-4
                lg:mt-0
                mr-4
              "
            >
              <router-link to="/about">About Us</router-link>
            </p>
            <router-link
              to="services"
              class="
                block
                mt-4
                lg:inline-block
                uppercase
                font-semibold
                text-lg
                md:px-4
                lg:mt-0
                mr-4
              "
              >Services</router-link
            >
            <router-link
              to="courses"
              class="
                block
                mt-4
                lg:inline-block
                uppercase
                font-semibold
                text-lg
                md:px-4
                lg:mt-0
                mr-4
              "
              >Courses</router-link
            >
            <!-- <router-link
              to="/#team"
              class="
                block
                mt-4
                lg:inline-block
                uppercase
                font-semibold
                text-lg
                md:px-4
                lg:mt-0
                mr-4
              "
              >The Team</router-link
            > -->
            <router-link
              to="contact"
              class="
                block
                mt-4
                lg:inline-block
                uppercase
                font-semibold
                text-lg
                md:px-4
                lg:mt-0
                mr-4
              "
              >Contact Us</router-link
            >
          </div>
        </div>
      </main>
    </nav>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      open: false,
    };
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
  },
};
</script>

<style scoped>
.my-header {
  color: white;
  background-color: #141720;
  z-index: 100;
}
.order-btn {
  background-color: #ffb601;
  color: #fff;
  text-transform: uppercase;
}
.order-btn2 {
  background-color: #efa706;
  color: #fff;
  text-transform: uppercase;
}
button:focus {
  outline: none;
}

img {
  width: 75px;
  height: 75px;
}
</style>
