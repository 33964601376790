<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "app",
  components: {},
  metaInfo: {
    title: "MOJ Catering",
    titleTemplate: "%s | MOJ Catering",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "MOJ catering school and hospitality management institute is the best catering school in Lagos Nigeria with carefully designed courses from novice to professional level such as Master Chef Certificate- African Dishes, Master Chef Certificate - Intercontinental Dishes, Master Chef Diploma, Pastries & Confectioneries Certificate, Cakes Master Certificate, Cakes Master Diploma, Events Chef & Cocktail Mixology Certificate, Diploma in Events Management, Special Course – African Dish Expert, Special Course – Hord d’oeuvres/Small Chops Expert, Special Course – Shawarma & Burgers Expert, Special Course – Cocktail, Smoothies & Milkshake Expert, Special Course – Desert Expert, Special Course – Snacks Expert, Special Course – Cakes Decoration & Sugar Craft and more",
      },
      {
        vmid: "keywords",
        name: "keywords",
        content:
          "Catering, Catering School, Catering school in Lagos, Catering School in Nigeria, Best Catering school in Lagos, Best Catering school in Nigeria, Baking school, cake baking school, pastries and confectionery school, sugar craft school, events planning school, hotel management school.",
      },
    ],
  },
};
</script>

<style>
#app {
  /* font-family: "Alata", Helvetica, Arial, sans-serif; */
  font-family: "Pathway Extreme", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.boxed__container {
  max-width: 1350px !important;
  margin: 0 auto;
}

.h-72 {
  height: 30rem;
}
</style>
