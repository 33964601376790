<template>
  <div>
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2  px-8 md:px-16 py-8">
        <p class="text-left font-semibold text-xl text-orange-500">
          Event Decoration Class
        </p>
        <p class="text-left">
          Decorating event halls is the art of transforming a reception venue
          into something that was only imagined, by individuals. It is the end
          result of painstaking effort to creatively beautifying a place, from
          wedding receptions and conferences seminars to funeral services, etc.
        </p>
        <br/>
        <p class="text-left">
          Join MOJ INSTITUTE OF CATERING & HOTEL MANAGEMENT to learn more about
          Event Decoration
        </p>

        <router-link
          to="/services#event"
          class="bg-orange-500 text-white p-2 m-2 rounded float-left"
          >Read More</router-link
        >
      </div>

      <div class="w-full md:w-1/2  px-8 md:px-16 py-8">
        <div>
          <div class="flex flex-wrap">
            <img
              class="w-full md:w-1/2 h-32 object-cover p-1"
              src="https://res.cloudinary.com/dldd8ucby/image/upload/w_1000,ar_1:1,c_fill,g_auto/v1579449142/MOJ/WhatsApp_Image_2020-01-14_at_18.55.50_1.jpg"
            />
            <img
              class="w-full md:w-1/2 h-32 object-cover p-1"
              src="https://res.cloudinary.com/dldd8ucby/image/upload/w_1000,ar_1:1,c_fill,g_auto/v1579449142/MOJ/WhatsApp_Image_2020-01-14_at_18.55.50.jpg"
            />
          </div>
          <div class="flex flex-wrap">
            <img
              class="w-full md:w-1/2 h-32 object-cover p-1"
              src="https://res.cloudinary.com/dldd8ucby/image/upload/w_1000,ar_1:1,c_fill,g_auto/v1579449140/MOJ/WhatsApp_Image_2020-01-14_at_18.55.52.jpg"
            />
            <img
              class="w-full md:w-1/2 h-32 object-fill p-1"
              src="https://res.cloudinary.com/dldd8ucby/image/upload/w_1000,ar_1:1,c_fill,g_auto/v1579449145/MOJ/WhatsApp_Image_2020-01-14_at_18.55.48.jpg"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {}
};
</script>

<style scoped>
div {
  text-align: center;
}
</style>
