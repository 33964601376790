<template>
  <div class="min-h-full py-8 flex flex-wrap" id="about">
    <div class="w-full md:w-1/2 about-text text-white px-8 md:px-16 py-16">
      <p class="text-2xl font-bold text-orange-500">About Us</p>
      <p>
        It’s our delight to have you on our site today as we both partner
        together to making your catering, hotel & hospitality management career
        come to life. MOJ catering and hospitality Management Institute is the
        best catering and hospitality school in Nigeria situated in the heart of
        Lagos to turn complete Novice into seasoned hospitality professionals.
      </p>
      <br />
      <p>
        Our Catering school is graced by experienced catering professionals in
        all areas of food creation to mentor you in the arts of catering and
        food management. We follow an international curriculum and train our
        students in intercontinental and inter-cultural food creation. Decide to
        begin your journey today with us and experience a flourishing culinary
        profession.
      </p>
      <br />
      <p>What we are known for</p>
      <ul>
        <li>Experienced Faculties</li>
        <li>International Curriculum</li>
        <li>Industry Recognized Certificate</li>
      </ul>

      <router-link
        to="/services"
        class="bg-orange-500 text-white p-2 mx-2 rounded float-left"
        >Learn More</router-link
      >
    </div>

    <img
      class="w-full md:w-1/2 object-cover"
      src="https://res.cloudinary.com/dldd8ucby/image/upload/v1611875787/MOJ/WhatsApp_Image_2021-01-12_at_3.46.57_PM_2.jpg"
    />
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.about-text {
  background-color: #fff;
  color: #141720;
}

ul {
  margin: 20px 50px;
}

ul li::before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #ed8936; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}
</style>
