<template>
  <div>
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2  px-8 md:px-16 py-8">
        <p class="text-left font-semibold text-xl text-orange-500">
          Cake Decoration Class
        </p>
        <p class="text-left">
          Cake, in general are any of a variety of breads, shortened or unshortened, usually shaped by the tin in which it is baked; more specifically, a sweetened bread, often rich or delicate.
        </p>
          <!-- The most commonly used cake ingredients include flour, sugar, eggs, butter or oil or margarine, a liquid, and leavening agents, such as baking soda or baking powder. -->

        <p class="text-left"> Cake is often served as a celebratory dish on ceremonial occasions, such as weddings, anniversaries, and birthdays or can be enjoyed as a simple meal</p>
        <br/>

        <p class="text-left">Join MOJ INSTITUTE OF CATERING & HOTEL MANAGEMENT to learn more about Cake Making and Decoration</p>

        <router-link to="/services#cake" class="bg-orange-500 text-white p-2 m-2 rounded float-left">Read More</router-link>
      </div>
      <div class="w-full md:w-1/2  px-8 md:px-16 py-16">
        <div>
          <div class="flex flex-wrap">
            <img
              class="w-full md:w-1/2 h-32 object-cover p-1"
              src="https://res.cloudinary.com/dldd8ucby/image/upload/w_1000,ar_1:1,c_fill,g_auto/v1579387220/MOJ/WhatsApp_Image_2020-01-14_at_18.56.21_1.jpg"
            />
            <img
              class="w-full md:w-1/2 h-32 object-fill p-1"
              src="https://res.cloudinary.com/dldd8ucby/image/upload/w_1000,ar_1:1,c_fill,g_auto/v1579387220/MOJ/WhatsApp_Image_2020-01-14_at_18.56.20.jpg"
            />
          </div>
          <div class="flex flex-wrap">
            <img
              class="w-full md:w-1/2 h-32 object-cover p-1"
              src="https://res.cloudinary.com/dldd8ucby/image/upload/w_1000,ar_1:1,c_fill,g_auto/v1579449138/MOJ/WhatsApp_Image_2020-01-14_at_18.56.12.jpg"
            />
            <img
              class="w-full md:w-1/2 h-32 object-fill p-1"
              src="https://res.cloudinary.com/dldd8ucby/image/upload/v1579449133/MOJ/WhatsApp_Image_2020-01-14_at_18.56.18_1.jpg"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {}
};
</script>

<style scoped>
div {
  text-align: center;
}
</style>
