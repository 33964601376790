<template>
  <div id="app">
    <Header />
    <main class="boxed__container">
      <p class="text-2xl font-bold mt-8 text-center text-orange-500">
        Our Courses
      </p>
      <p class="text-lg">
        We offer a wide range of courses designed to meet students schedules and
        training needs. These courses can be offered as full time or part time
        courses. We also offer bespoke courses designed to only cover key topics
        that you wish to learn or improve in. Kindly call the school desk to
        discuss your options.
      </p>
      <div>
        <div class="flex flex-wrap">
          <div
            v-for="course in courses"
            :key="course"
            id="cake"
            class="w-full px-8 md:px-16 py-8 shadow-lg rounded-lg mb-8"
          >
            <p class="font-bold text-xl text-orange-500">
              {{ course.name }}
            </p>
            <span
              v-for="intro in course.intro"
              :key="intro"
              class="text-base font-light"
            >
              {{ intro }}
            </span>

            <main class="m-4">
              <li
                v-for="introList in course.introList"
                :key="introList"
                class="text-sm"
              >
                {{ introList }}
              </li>
            </main>

            <span
              v-for="outro in course.outro"
              :key="outro"
              class="text-base font-light"
            >
              {{ outro }}
            </span>

            <p class="font-bold text-md my-2 text-orange-500">
              Admission Process & Eligibility Criteria
            </p>

            <p
              v-for="admissionCriteria in course.admissionCriteria"
              :key="admissionCriteria"
              class="text-sm my-2"
            >
              {{ admissionCriteria }}
            </p>

            <p class="text-sm my-1">
              <span class="text-orange-500">Admission Date: </span>
              {{ course.summary.admissionDate }}
            </p>
            <p class="text-sm my-1">
              <span class="text-orange-500">Program Duration: </span>
              {{ course.summary.programDuration }}
            </p>
            <p class="text-sm my-1">
              <span class="text-orange-500">Credential Type: </span>
              {{ course.summary.credentialType }}
            </p>
            <p class="text-sm my-1">
              <span class="text-orange-500">Admission Fee: </span>
              {{ course.summary.fee }}
            </p>
            <p class="text-sm my-1">
              <span class="text-orange-500">Tuition: </span>
              {{ course.summary.tuition }}
            </p>
          </div>
          <hr />
        </div>
      </div>

      <div>
        <p class="font-bold text-xl text-orange-500">Students Moment Gallery</p>
        <span class="text-base font-light">
          We have captured some interesting moments of wonderful things our
          students are currently doing at MOJ catering school and hospitality
          management institute. We are all about real-life catering projects and
          hands-on practical. Hence our students spend a large part of their
          time in the catering kitchen doing a lot of hands-on practicals.
        </span>
        <div class="w-full px-8 md:px-16 py-8">
          <div>
            <div class="flex flex-wrap">
              <img
                class="w-full md:w-1/4 h-64 object-cover p-1"
                src="https://res.cloudinary.com/dldd8ucby/image/upload/v1651476908/MOJ/WhatsApp_Image_2022-04-16_at_6.31.43_PM_3.jpg"
              />

              <!-- <img
                class="w-full md:w-1/4 h-64 object-cover p-1"
                src="https://res.cloudinary.com/dldd8ucby/image/upload/v1651476908/MOJ/WhatsApp_Image_2022-04-16_at_6.31.43_PM.jpg"
              /> -->
              <img
                class="w-full md:w-1/4 h-64 object-fill p-1"
                src="https://res.cloudinary.com/dldd8ucby/image/upload/v1651476907/MOJ/WhatsApp_Image_2022-04-16_at_6.31.42_PM_2.jpg"
              />

              <img
                class="w-full md:w-1/4 h-64 object-cover p-1"
                src="https://res.cloudinary.com/dldd8ucby/image/upload/v1651476908/MOJ/WhatsApp_Image_2022-04-16_at_6.31.43_PM_2.jpg"
              />

              <img
                class="w-full md:w-1/4 h-64 object-cover p-1"
                src="https://res.cloudinary.com/dldd8ucby/image/upload/v1651476797/MOJ/WhatsApp_Image_2022-04-16_at_6.29.58_PM.jpg"
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        <p class="font-bold text-xl text-orange-500">Our Awesome Teachers</p>
        <span class="text-base font-light">
          MOJ Catering School and Hospitality Management Institute Lagos
          Nigeria, has the best of catering and hospitality management faculties
          and tutors to coach you into achieving your goals as a professional
          chef or events planner.
        </span>
        <Team />
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header2.vue";
import Footer from "@/components/Footer.vue";
import Team from "@/components/Team.vue";

export default {
  components: {
    Header,
    Footer,
    Team,
  },
  metaInfo: {
    title: "Courses",
  },
  data() {
    return {
      courses: [
        {
          name: "Master Chef Certificate- African Dishes",
          intro: [
            "Welcome to our Chef Master Certificate with Specialty on African Dishes. This course is a 3 Month course rendered full time or part time that focus on West Africa Dishes. Upon completion of the course student will be ",
          ],

          introList: [
            "Experts at making over 30 Africa delicacies",
            "Experts at serving & plating African dishes in esthetically pleasing way",
            "Understand the West African Culture and Learn how to tailor each dish to suite different tribes",
            "Learn to Understand different varieties of the same dish as it is enjoyed by different tribe",
            "Gain access to secret master recipes that makes African dish taste completely unique and extraordinary",
            "Obtain a Master Chef Certificate from MOJ Catering and Hospitality Management Institute.",
          ],
          outro: [
            "If you are looking to become an Expert Chef in making African Dishes, then MOJ Catering, and Hospitality Management Institute is the prime company to partner with you and make this a reality. ",
          ],
          admissionCriteria: [
            "To get admitted into this 3 Months certificate program. Fill out the below inquiry form and one of our staff will reach out to you immediately. You can also visit our Facebook page on the below link and drop a message on Facebook Messenger and we will respond to you immediately.",
            "OR, if you would like to call us, our phone line is always open 24/7. Give us a call and we can have a conversation regarding this program.",
          ],
          summary: {
            admissionDate: "Admission is currently opened",
            programDuration: "3 Months",
            credentialType:
              "Upon Completion we will award you with the MOJ Catering and Hospitality Management Institute Master Chef Certificate",
            fee: "NGN 1,500",
            tuition:
              "Call the registrar at full tuition or fill out the inquiry form and we will call you.",
          },
        },
        {
          name: "Master Chef Certificate - Intercontinental Dishes",
          intro: [
            "The MOJ Catering and Hospitality Management Master Chef Certification in Intercontinental Dishes is a unique an exciting culinary course rendered fulltime or parttime that focus of European, Chinees, India, and American Delicacies. Students completing this course will be equipped with the following skills",
          ],

          introList: [
            "Prepare Over 40 European, Chinees, India, and American Delicacies",
            "Prepare international starter meals and desert",
            "Understand different international cultures and special Ingredients",
            "Plating and Serving of Intercontinental dishes in a superb and esthetically pleasing manner",
            "Special Recipes, Tip & Tricks, and secret ingredient to make your meal standout",
            "Obtain a Master Chef Certificate from MOJ Catering and Hospitality Management Institute.",
          ],
          outro: [
            "If you are looking to become an Expert Chef in making Intercontinental Dishes, then MOJ Catering, and Hospitality Management Institute is the prime company to partner with you and make this a reality.",
          ],
          admissionCriteria: [
            "To get admitted into this 3 Months certificate program. Fill out the below inquiry form and one of our staff will reach out to you immediately. You can also visit our Facebook page on the below link and drop a message on Facebook Messenger and we will respond to you immediately.",
            "OR, if you would like to call us, our phone line is always open 24/7. Give us a call and we can have a conversation regarding this program.",
          ],
          summary: {
            admissionDate: "Admission is currently opened",
            programDuration: "3 Months",
            credentialType:
              "Upon Completion we will award you with the MOJ Catering and Hospitality Management Institute Master Chef Certificate",
            fee: "NGN 1,500",
            tuition:
              "Call the registrar at full tuition or fill out the inquiry form and we will call you.",
          },
        },
        {
          name: "Master Chef Diploma (Combined)",
          intro: [
            "The Master Chef Diploma is a complete 6 Month diploma that combines all our cooking coursework into one to create a robust course for anyone who wish to be specialized in making both African and Intercontinental dishes. The Master Chef diploma is rendered as full-time and part time and combines all the course work from the African and International dish master chef certificate course. In this course you will learn the following",
          ],

          introList: [
            "Experts at making over 30 Africa delicacies",
            "Experts at serving & plating African dishes in esthetically pleasing way",
            "Understand the West African Culture and Learn how to tailor each dish to suite different tribes",
            "Learn to Understand different varieties of the same dish as it is enjoyed by different tribe",
            "Gain access to secret master recipes that makes African dish taste completely unique and extraordinary",
            "Prepare Over 40 European, Chinees, India, and American Delicacies",
            "Prepare international starter meals and desert",
            "Understand different international cultures and special Ingredients",
            "Plating and Serving of Intercontinental dishes in a superb and esthetically pleasing manner",
            "Special Recipes, Tip & Tricks, and secret ingredient to make your meal standout.",
            "Obtain a Master Chef Certificate from MOJ Catering and Hospitality Management Institute.",
          ],
          outro: [""],
          admissionCriteria: [
            "To get admitted into this 6 Months certificate program. Fill out the below inquiry form and one of our staff will reach out to you immediately. You can also visit our Facebook page on the below link and drop a message on Facebook Messenger and we will respond to you immediately.",
            "OR, if you would like to call us, our phone line is always open 24/7. Give us a call and we can have a conversation regarding this program.",
          ],
          summary: {
            admissionDate: "Admission is currently opened",
            programDuration: "6 Months",
            credentialType:
              "Upon Completion we will award you with the MOJ Catering and Hospitality Management Institute Master Chef Certificate",
            fee: "NGN 1,500",
            tuition:
              "Call the registrar at full tuition or fill out the inquiry form and we will call you.",
          },
        },
        {
          name: "Pastries & Confectioneries Certificate",
          intro: [
            "MOJ Catering and Hospitality Management Institute offers this interesting 2 Months certificate course on pastries and confectioneries that cover the following coursework",
          ],

          introList: [
            "Learn how to make popular Nigeria snacks and pastries such as Puff-Puff, Coconut Candy, Chin Chin, Akara (Bean Cakes), Meat Pie, Donkwa, Egg rolls, Kokoro, Kuli Kuli (Groundnut Cake), Bread, Butter Cake, Doughnut, Chin Chin, Chocolate Cake, Fruit Cake, Cookies, small Chops pastries (plantain mosa, samosa, spring roll), shawarma, Chicken pie, Pop Corn, Club Sandwich, Scotched Eggs, Egg roll, Rolled Crepes, Cup Cakes and more.",
            "Intercontinental Pastries such as Pizzas, Burgers, Sandwich, Pies, Glazed and Decorated doughnut, muffins, hotdogs and more",
            "Learn how to use baking equipment’s to make pastries in small and large scale",
            "Obtain a Master Chef Certificate from MOJ Catering and Hospitality Management Institute.",
          ],
          outro: [""],
          admissionCriteria: [
            "To get admitted into this 2 Months certificate program. Fill out the below inquiry form and one of our staff will reach out to you immediately. You can also visit our Facebook page on the below link and drop a message on Facebook Messenger and we will respond to you immediately.",
            "OR, if you would like to call us, our phone line is always open 24/7. Give us a call and we can have a conversation regarding this program.",
          ],
          summary: {
            admissionDate: "Admission is currently opened",
            programDuration: "2 Months",
            credentialType:
              "Upon Completion we will award you with the MOJ Catering and Hospitality Management Institute Master Chef Certificate",
            fee: "NGN 1,500",
            tuition:
              "Call the registrar at full tuition or fill out the inquiry form and we will call you.",
          },
        },
        {
          name: "Cakes Master Certificate",
          intro: [
            "Do you want to learn how to bake mind blowing, beautiful and esthetically pleasing cakes for all time to moments, events, and occasion? Do you wish to become a master in cake making and decoration of all kinds? At MOJ Catering and Hospitality management institute we are masters in the art of conceptualization, design, baking, decoration, and presentation of tasty and beautiful cakes to meet all events, either birthday cakes, anniversary cakes, corporate cakes, promotions cakes, weddings cakes and more, MOJ is the best catering school in Lagos Nigeria to acquire this rear, esteemed and professional skills.",
            "We have expert faculties and tutors who have spent a large part of their professional career designing and executing the best cakes for the right events and are ready to transfer such knowledge to intending bakers and chefs who wish to replicate the same success story and become cake baking experts as well.",
            "Our curriculum teaches everything you need to know in just 3 months we will be covering the following topics",
          ],

          introList: [
            "Introduction to cake baking tools, equipment’s, and ingredients to assemble and frost a wonderful cake",
            "Cake planning, Color selection, Conceptualization, Designing and preparing an execution plan for the right cake and event",
            "Techniques on cake mixing",
            "Heating techniques on how to bake a cake",
            "Cake Layering and shaping to meet design specifications",
            "Frosting round, square, and custom shapes cakes",
            "Cakes finishing and decorations.",
            "Cakes Storage and presentation",
          ],
          outro: [
            "During this bake caking course, you will execute a lot of cake baking project on your own under the supervision of our tutors and finally, you will have to deliver a final project at the end of the course that incorporate all that you have learnt to the detail. This project will be presented to a team of expert bakers to award you the MOJ catering, and hospitality management institute cakes master certificate.",
          ],
          admissionCriteria: [
            "To get admitted into this 3 Months certificate program. Fill out the below inquiry form and one of our staff will reach out to you immediately. You can also visit our Facebook page on the below link and drop a message on Facebook Messenger and we will respond to you immediately.",
            "OR, if you would like to call us, our phone line is always open 24/7. Give us a call and we can have a conversation regarding this program.",
          ],
          summary: {
            admissionDate: "Admission is currently opened",
            programDuration: "3 Months",
            credentialType:
              "Upon Completion we will award you with the MOJ Catering and Hospitality Management Institute Master Chef Certificate",
            fee: "NGN 1,500",
            tuition:
              "Call the registrar at full tuition or fill out the inquiry form and we will call you.",
          },
        },
        {
          name: "Cakes Master Diploma",
          intro: [
            "Do you want to learn how to bake mind blowing, beautiful and esthetically pleasing cakes for all time to moments, events, and occasion? Do you wish to become a master in cake making and decoration of all kinds? At MOJ Catering and Hospitality management institute we are masters in the art of conceptualization, design, baking, decoration, and presentation of tasty and beautiful cakes to meet all events, either birthday cakes, anniversary cakes, corporate cakes, promotions cakes, weddings cakes and more, MOJ is the best catering school in Lagos Nigeria to acquire this rear, esteemed and professional skills.",
            "We have expert faculties and tutors who have spent a large part of their professional career designing and executing the best cakes for the right events and are ready to transfer such knowledge to intending bakers and chefs who wish to replicate the same success story and become cake baking experts as well.",
            "The Six Months Cakes Master diploma offered by MOJ Catering and Hospitality Institute Lagos Nigeria is a combination of 2 key coursework which are the Cake master course work that covers a beginner to expert level of cake baking and decoration, and an advanced course in sugar craft that teaches you all you need to know to animate your cake into a real-life representation or design that would definitely suit the event and wow your clientele. The following coursework will be covered as part of MOJ catering and hospitality management Lagos Cakes master diploma",
          ],

          introList: [
            "Beginner to Expert in Cake Baking & Frosting",
            "Introduction to cake baking tools, equipment’s, and ingredients to assemble and frost a wonderful cake",
            "Cake planning, Color selection, Conceptualization, Designing and preparing an execution plan for the right cake and event",
            "Techniques on cake mixing",
            "Heating techniques on how to bake a cake",
            "Cake Layering and shaping to meet design specifications",
            "Frosting round, square, and custom shapes cakes",
            "Cakes finishing and decorations",
            "Cakes Storage and presentation",
            "Advanced Knowledge of Sugar Craft",
            "Drawing and Design",
            "Colors Management",
            "Making small sugar craft props and artifacts",
            "Making Large objects",
            "Redesigning your cake with sugar craft concepts",
          ],
          outro: [
            "During this bake caking course, you will execute a lot of cake baking project on your own under the supervision of our tutors and finally, you will have to deliver a final project at the end of the course that incorporate all that you have learnt to the detail. This project will be presented to a team of expert bakers to award you the MOJ catering, and hospitality management institute cakes master diploma.",
          ],
          admissionCriteria: [
            "To get admitted into this 6 Months certificate program. Fill out the below inquiry form and one of our staff will reach out to you immediately. You can also visit our Facebook page on the below link and drop a message on Facebook Messenger and we will respond to you immediately.",
            "OR, if you would like to call us, our phone line is always open 24/7. Give us a call and we can have a conversation regarding this program.",
          ],
          summary: {
            admissionDate: "Admission is currently opened",
            programDuration: "6 Months",
            credentialType:
              "Upon Completion we will award you with the MOJ Catering and Hospitality Management Institute Master Chef Certificate",
            fee: "NGN 1,500",
            tuition:
              "Call the registrar at full tuition or fill out the inquiry form and we will call you.",
          },
        },
        {
          name: "Cakes Master Certificate Fast Track",
          intro: [
            "Welcome to one of MOJ catering and hospitality management esteemed courses. This course falls under a special category called the Fast Track program and what we do is that we shrink and entire 3 months certificate course into a 1-month intensive program. The one-month intensive program demands that the student dedicate the most part of one month to complete our cakes master certification program. For our Fast track options, we offer flexible timing that will support full time and part time students, to discuss your options and how you intend to tailor your time to complete this program. Please call our help desk to and you will speak with someone that will help you register and setup the program for you.",
            "It is time to begin an exciting journey in the culinary arts and become a cake master within a month. Do you want to learn how to bake mind blowing, beautiful and esthetically pleasing cakes for all time to moments, events, and occasion? Do you wish to become a master in cake making and decoration of all kinds? At MOJ Catering and Hospitality management institute we are masters in the art of conceptualization, design, baking, decoration, and presentation of tasty and beautiful cakes to meet all events, either birthday cakes, anniversary cakes, corporate cakes, promotions cakes, weddings cakes and more, MOJ is the best catering school in Lagos Nigeria to acquire this rear, esteemed and professional skills.",
            "We have expert faculties and tutors who have spent a large part of their professional career designing and executing the best cakes for the right events and are ready to transfer such knowledge to intending bakers and chefs who wish to replicate the same success story and become cake baking experts as well.",
            "Our curriculum teaches everything you need to know in just 3 months we will be covering the following topics",
          ],

          introList: [
            "Introduction to cake baking tools, equipment’s, and ingredients to assemble and frost a wonderful cake",
            "Cake planning, Color selection, Conceptualization, Designing and preparing an execution plan for the right cake and event",
            "Techniques on cake mixing",
            "Heating techniques on how to bake a cake",
            "Cake Layering and shaping to meet design specifications",
            "Frosting round, square, and custom shapes cakes",
            "Cakes finishing and decorations",
            "Cakes Storage and presentation",
          ],
          outro: [""],
          admissionCriteria: [
            "During this bake caking course, you will execute a lot of cake baking project on your own under the supervision of our tutors and finally, you will have to deliver a final project at the end of the course that incorporate all that you have learnt to the detail. This project will be presented to a team of expert bakers to award you the MOJ catering, and hospitality management institute cakes master certificate.",
            "To get admitted into this 1 Months certificate program. Fill out the below inquiry form and one of our staff will reach out to you immediately. You can also visit our Facebook page on the below link and drop a message on Facebook Messenger and we will respond to you immediately.",
          ],
          summary: {
            admissionDate: "Admission is currently opened",
            programDuration: "1 Months",
            credentialType:
              "Upon Completion we will award you with the MOJ Catering and Hospitality Management Institute Master Chef Certificate",
            fee: "NGN 1,500",
            tuition:
              "Call the registrar at full tuition or fill out the inquiry form and we will call you.",
          },
        },
        {
          name: "Events Chef & Cocktail Mixology Certificate",
          intro: [
            "Events are the ideal scenarios for master chefs to shine and make their culinary skills known. This is because you are preparing dishes for a lot of people and hence need to plan your ingredients quantity, recipes, delivery, and overall presentation. Either you are cooking for a large buffet, wedding events, birthday ceremonies or religious gatherings, that art of mastering combination of various food choice and drinks is key to this process.",
            "For this reason, MOJ Catering school Lagos, has developed this dedicated and special 3 months program to train you on how to prepare African and intercontinental dishes for the right occasion coupled with cocktail and drink preparation. At the end of the 3 months master class, you will be awarded the MOJ Event Chef and Cocktail Mixology Certificate.",
            "The program covers the following coursework",
          ],

          introList: [
            "Food planning for a lot of guests: Understanding the client requirements, market analysis and sampling, pricing, and quotation, securing an event catering deal",
            "Equipment’s, Shopping & Catering Team: How to assemble the right catering team for an Event, How and where to shop in bulk, wholesale dealers, setup you are cooking area, time management, team management.",
            "Cooking for the Nigerian Event: Now we move into the fun part and start teaching you how to make Nigerian Food across multiple cultures and regions of the country. How to make the best party jollof rice, fried rice, pasta & spaghetti, proteins (meat, fish, chicken….), Moi-Moi, small chops, coleslaw & salad, coconut rice and a host of other Nigerian event foods and condiments.",
            "Cooking Intercontinental Meals for Corporate Events & White-Collar Parties:  Next, we will teach you how to make Chinese cuisines, Indian cuisines, European delicacies, intercontinental sea food meals and a host of others to give your event and intercontinental theme and style.",
            "Nigerian and Intercontinental Desserts: Next, we will teach you on how to make Nigerian pastries and snacks for events, intercontinental dessert options and cakes suitable for large events.",
            "What is an Event without Drinks: To make this a complete course and to make you a all-round expert at planning and preparing food for special occasions and events, we have included a cocktail course that will teach you how to make different type of Nigerian and intercontinental cocktails.",
            "Finally, Serving and Presentation: We will then end this 3-month course, with a bonus coursework where we teach you on how to present and serve your prepared meals in an events. This will include how to put together your serving team, how to setup your buffet table, food plating and guest management.",
          ],
          outro: [""],
          admissionCriteria: [
            "To get admitted into this 3 Months certificate program. Fill out the below inquiry form and one of our staff will reach out to you immediately. You can also visit our Facebook page on the below link and drop a message on Facebook Messenger and we will respond to you immediately.",
            "OR, if you would like to call us, our phone line is always open 24/7. Give us a call and we can have a conversation regarding this program.",
          ],
          summary: {
            admissionDate: "Admission is currently opened",
            programDuration: "3 Months",
            credentialType:
              "Upon Completion we will award you with the MOJ Catering and Hospitality Management Institute Master Chef Certificate",
            fee: "NGN 1,500",
            tuition:
              "Call the registrar at full tuition or fill out the inquiry form and we will call you.",
          },
        },
        {
          name: "Special Course – African Dish Expert",
          intro: [
            "The special course is a 1-month course that MOJ catering and Hospitality Management Institute offer to provide a crash, intensive course to students who wish to quickly develop proficiency in a specific area of catering. In this special course, which can be offered full time or part time, we will be teaching students the following ",
          ],

          introList: [
            "How to make over 30 Africa delicacies",
            "Serving & plating African dishes in esthetically pleasing way",
            "Understand the West African Culture and Learn how to tailor each dish to suite different tribes",
            "Learn to Understand different varieties of the same dish as it is enjoyed by different tribe",
            "Gain access to secret master recipes that makes African dish taste completely unique and extraordinary",
          ],
          outro: [
            "Upon completion of this special course, you will receive an MOJ catering and hospitality institute certificate of completion – African Dish Expert. ",
          ],
          admissionCriteria: [
            "To get admitted into this 1 Months certificate program. Fill out the below inquiry form and one of our staff will reach out to you immediately. You can also visit our Facebook page on the below link and drop a message on Facebook Messenger and we will respond to you immediately.",
            "OR, if you would like to call us, our phone line is always open 24/7. Give us a call and we can have a conversation regarding this program.",
          ],
          summary: {
            admissionDate: "Admission is currently opened",
            programDuration: "1 Months",
            credentialType:
              "Upon Completion we will award you with the MOJ Catering and Hospitality Management Institute Master Chef Certificate",
            fee: "NGN 1,500",
            tuition:
              "Call the registrar at full tuition or fill out the inquiry form and we will call you.",
          },
        },
        {
          name: "Special Course – Hord d’oeuvres/Small Chops Expert",
          intro: [
            "The special course is a 1-month course that MOJ catering, and Hospitality Management Institute offer to provide a crash, intensive course to students who wish to quickly develop proficiency in a specific area of catering. In this special course, which can be offered full time or part time, we will be teaching students the following:",
            "How to make all pastries and confectioneries in a small chop pack as as:",
          ],

          introList: [
            "Mini Sausage rolls",
            "Snails",
            "Peppered Gizzards",
            "Barbecued Goat Meat Asun",
            "Puff Puff",
            "Beer Battered Fish",
            "Samosas",
            "Spring rolls",
            "Kebab (Stick Meat)",
            "Gizdodo",
            "Meat Pie",
            "Chin Chin",
            "Yoyo n Shrimps",
          ],
          outro: [
            "How to arrange and package a beautiful small chop packs for events, delivery, and other scenarios to draw the cravings of your intending customers",
            "Upon completion of this special course, you will receive an MOJ catering and hospitality institute certificate of completion – Hord d’oeuvres/Small Chops Expert. ",
          ],
          admissionCriteria: [
            "To get admitted into this 1 Months certificate program. Fill out the below inquiry form and one of our staff will reach out to you immediately. You can also visit our Facebook page on the below link and drop a message on Facebook Messenger and we will respond to you immediately.",
            "OR, if you would like to call us, our phone line is always open 24/7. Give us a call and we can have a conversation regarding this program.",
          ],
          summary: {
            admissionDate: "Admission is currently opened",
            programDuration: "1 Months",
            credentialType:
              "Upon Completion we will award you with the MOJ Catering and Hospitality Management Institute Master Chef Certificate",
            fee: "NGN 1,500",
            tuition:
              "Call the registrar at full tuition or fill out the inquiry form and we will call you.",
          },
        },
        {
          name: "Special Course – Shawarma & Burgers Expert",
          intro: [
            "The special course is a 1-month course that MOJ catering and Hospitality Management Institute offer to provide a crash, intensive course to students who wish to quickly develop proficiency in a specific area of catering. In this special course, which can be offered full time or part time, we will be teaching students the following ",
          ],

          introList: [
            "How to use and maintain shawarma equipment’s",
            "How to make different types of shawarma and shawarma sauce",
            "How to make different types of burgers",
          ],
          outro: [
            "Upon completion of this special course, you will receive an MOJ catering and hospitality institute certificate of completion – Special Course – Shawarma & Burgers Expert",
          ],
          admissionCriteria: [
            "To get admitted into this 1 Months certificate program. Fill out the below inquiry form and one of our staff will reach out to you immediately. You can also visit our Facebook page on the below link and drop a message on Facebook Messenger and we will respond to you immediately.",
            "OR, if you would like to call us, our phone line is always open 24/7. Give us a call and we can have a conversation regarding this program.",
          ],
          summary: {
            admissionDate: "Admission is currently opened",
            programDuration: "1 Months",
            credentialType:
              "Upon Completion we will award you with the MOJ Catering and Hospitality Management Institute Certificate of Completion – Shawarma & Burgers Expert",
            fee: "NGN 1,500",
            tuition:
              "Call the registrar at full tuition or fill out the inquiry form and we will call you.",
          },
        },
        {
          name: "Special Course – Cocktail, Smoothies & Milkshake Expert",
          intro: [
            "The special course is a 1-month course that MOJ catering, and Hospitality Management Institute offer to provide a crash, intensive course to students who wish to quickly develop proficiency in a specific area of catering. In this special course, which can be offered full time or part time, we will be teaching students the following ",
          ],

          introList: [
            "How to make different flavors of fruits and cream smoothies",
            "How to make alcoholic and no alcoholic cocktails",
            "How to make different blends of milkshakes",
            "Serving your cocktails to wow your customers",
          ],
          outro: [
            "Upon completion of this special course, you will receive an MOJ catering and hospitality institute certificate of completion – African Dish Expert",
          ],
          admissionCriteria: [
            "To get admitted into this 1 Months certificate program. Fill out the below inquiry form and one of our staff will reach out to you immediately. You can also visit our Facebook page on the below link and drop a message on Facebook Messenger and we will respond to you immediately.",
            "OR, if you would like to call us, our phone line is always open 24/7. Give us a call and we can have a conversation regarding this program.",
          ],
          summary: {
            admissionDate: "Admission is currently opened",
            programDuration: "1 Months",
            credentialType:
              "Upon Completion we will award you with the MOJ Catering and Hospitality Management Institute Certificate of Completion – Cocktail, Smoothies & Milkshake Expert",
            fee: "NGN 1,500",
            tuition:
              "Call the registrar at full tuition or fill out the inquiry form and we will call you.",
          },
        },
        {
          name: "Special Course – Desert Expert",
          intro: [
            "The special course is a 1-month course that MOJ catering, and Hospitality Management Institute offer to provide a crash, intensive course to students who wish to quickly develop proficiency in a specific area of catering. In this special course, which can be offered full time or part time, we will be teaching students the following ",
          ],

          introList: [
            "How to make yummy African Deserts",
            "How to make intercontinental Deserts",
          ],
          outro: [
            "Upon completion of this special course, you will receive an MOJ catering and hospitality institute certificate of completion – Desert Expert",
          ],
          admissionCriteria: [
            "To get admitted into this 1 Months certificate program. Fill out the below inquiry form and one of our staff will reach out to you immediately. You can also visit our Facebook page on the below link and drop a message on Facebook Messenger and we will respond to you immediately.",
            "OR, if you would like to call us, our phone line is always open 24/7. Give us a call and we can have a conversation regarding this program.",
          ],
          summary: {
            admissionDate: "Admission is currently opened",
            programDuration: "1 Months",
            credentialType:
              "Upon Completion we will award you with the MOJ Catering and Hospitality Management Institute Certificate of Completion – Desert Expert",
            fee: "NGN 1,500",
            tuition:
              "Call the registrar at full tuition or fill out the inquiry form and we will call you.",
          },
        },
        {
          name: "Special Course – Snacks Expert",
          intro: [
            "The special course is a 1-month course that MOJ catering, and Hospitality Management Institute offer to provide a crash, intensive course to students who wish to quickly develop proficiency in a specific area of catering. In this special course, which can be offered full time or part time, we will be teaching students the following:",
          ],

          introList: [
            "How to make a variety of African Snacks and Pastries",
            "How to make Intercontinental snack and pastries",
            "How to package and present your snakes to your customers",
          ],
          outro: [
            "Upon completion of this special course, you will receive an MOJ catering and hospitality institute certificate of completion – African Dish Expert. ",
          ],
          admissionCriteria: [
            "To get admitted into this 3 Months certificate program. Fill out the below inquiry form and one of our staff will reach out to you immediately. You can also visit our Facebook page on the below link and drop a message on Facebook Messenger and we will respond to you immediately.",
            "OR, if you would like to call us, our phone line is always open 24/7. Give us a call and we can have a conversation regarding this program.",
          ],
          summary: {
            admissionDate: "Admission is currently opened",
            programDuration: "3 Months",
            credentialType:
              "Upon Completion we will award you with the MOJ Catering and Hospitality Management Institute Certificate of Completion – African Dish Expert",
            fee: "NGN 1,500",
            tuition:
              "Call the registrar at full tuition or fill out the inquiry form and we will call you.",
          },
        },
        {
          name: "Special Course – Cakes Decoration & Sugar Craft",
          intro: [
            "The special course is a 1-month course that MOJ catering, and Hospitality Management Institute offer to provide a crash, intensive course to students who wish to quickly develop proficiency in a specific area of catering. In this special course, which can be offered full time or part time, we will be teaching students the following:",
          ],

          introList: [
            "How to perform icing sugar and butter cakes decoration",
            "How to design cakes with fondant icing",
            "How to plan a cake design",
            "How to make small sugar craft artifacts and objects",
            "How to design your cakes to represents shapes and objects",
            "Sugar Craft and Colors management",
          ],
          outro: [
            "Upon completion of this special course, you will receive an MOJ catering and hospitality institute certificate of completion – Cakes Decoration & Sugar Craft",
          ],
          admissionCriteria: [
            "To get admitted into this 1 Months certificate program. Fill out the below inquiry form and one of our staff will reach out to you immediately. You can also visit our Facebook page on the below link and drop a message on Facebook Messenger and we will respond to you immediately.",
            "OR, if you would like to call us, our phone line is always open 24/7. Give us a call and we can have a conversation regarding this program.",
          ],
          summary: {
            admissionDate: "Admission is currently opened",
            programDuration: "1 Months",
            credentialType:
              " Upon Completion we will award you with the MOJ Catering and Hospitality Management Institute Certificate of Completion – Cakes Decoration & Sugar Craft",
            fee: "NGN 1,500",
            tuition:
              "Call the registrar at full tuition or fill out the inquiry form and we will call you.",
          },
        },
      ],
    };
  },
};
</script>

<style></style>
