<template>
  <div id="app">
    <Header />
    <main class="boxed__container">
      <p class="text-2xl font-bold mt-8 text-center text-orange-500">
        About Us
      </p>
      <p class="text-lg text-center">Welcome to MOJ Catering School</p>

      <main>
        <p class="text-left">
          It’s a delight to have you at our official website today, MOJ Catering
          school and Hospitality Management Institute is the best and leading
          catering school in Lagos Nigeria. We have seasoned teachers and
          faculties with years of experience in the culinary, hotel management,
          event management, hospitality, and concierge industry. We have
          designed special coursework and certificate programs tailored to teach
          you the most of a given profession in the catering and hospitality
          industry. Our approach is that of coaching aspiring students to become
          leading catering and hospitality management professionals that are fit
          and ready to commence a fruitful and flourishing career immediately
          after completing a program with Us.
        </p>

        <p class="text-left">
          We encourage you to look at all our coursework and choose a program
          that is best suited for your catering and hospitality management
          journey. When you have a program, please fill out the inquiry form or
          give us a call. We will be more than willing to assist you in getting
          enrolled for the program and commencing immediately.
        </p>
      </main>
      <div>
        <div class="flex flex-wrap">
          <div id="cake" class="w-full px-8 md:px-16 py-8">
            <p class="text-left font-semibold text-xl text-orange-500">
              Our Mission & Vision Statements
            </p>
            <p class="text-left">
              To raise seasoned professionals in the culinary and hospitality
              industry with suitable pedigree to disrupt the norms with new
              approach and ideas that would offer clients remarkable
              experiences.
            </p>

            <p class="text-left">
              We hope to achieve this vision, by implementing these underlying
              mission programs
            </p>

            <li>
              To engage seasoned professionals in the catering and hospitality
              industry as tutors and faculties to ensure that our students get
              the best in their chosen profession.
            </li>

            <li>
              To invest in the right equipment’s, catering facilities and
              materials to ensure we deliver a practical-centric approach across
              all our programs
            </li>

            <li>
              To design affordable and dedicated programs, packaged with all
              necessary coursework, to turn a complete novice into an
              experienced professional.
            </li>
          </div>
        </div>

        <div class="flex flex-wrap">
          <div id="cake" class="w-full px-8 md:px-16 py-8">
            <p class="text-left font-semibold text-xl text-orange-500">
              Why Choose Us
            </p>
            <p class="text-left">
              There are a host of Catering schools in Lagos Nigeria, but we are
              going to tell you why MOJ catering school and hospitality
              management institute remains the best amongst all of them. We
              remain unrivalled and continue to stay ahead of the competition
              because we don’t just teach catering and hospitality management,
              but we raise catering and hospitality management professionals.
            </p>

            <p class="text-left">
              What make MOJ catering school and hospitality management institute
              unique, is our approach in mentoring intending culinary
              professionals. We implement a very practical approach, ensuring
              that all our students get a lot of hands-on experience and perform
              numerous hands-on projects until what we are teaching becomes
              second nature to them.
            </p>

            <p class="text-left">
              It is this approach that makes every alumnus of MOJ catering
              school and hospitality management institute go ahead to become
              leaders in the culinary and hospitality industry.
            </p>

            <li>
              Experience Tutors: All our faculties and tutors are industry
              leaders in the culinary and hospitality industry with years of
              hands-on experience. Every class with them is not just a teaching
              venture but a professional mentoring session as they dedicate time
              in teaching special techniques, secret recipes, professional and
              career tips.
            </li>

            <li>
              Practical-First: It is practical first at MOJ catering school and
              hospitality management institute Lagos. It’s all about raising
              students who can work-the-work as well as they can talk-the-talk.
              Hence, most of our time is spent in the Kitchen or the Lab where
              we demonstrate what we are teaching and get you to do projects on
              your own.
            </li>

            <li>
              Best Programs: All our programs are tailored from Novice to
              Professional/Expert Level, so we anticipate that you might be
              starting a program with zero experience, and we have developed our
              program with loads of coursework to take you from a novice to a
              professional in the shortest time possible
            </li>

            Students Support: When you complete a program with us, that is not
            the end of the journey, we then proceed to partner with you in your
            career and professional journey, this involves guiding you in
            obtaining a professional Job or commencing a professional journey.
          </div>
        </div>

        <div class="flex flex-wrap">
          <div id="cake" class="w-full px-8 md:px-16 py-8">
            <p class="text-left font-semibold text-xl text-orange-500">
              Faculties & Staff
            </p>
            <p class="text-left">
              Welcome to MOJ Catering School and Hospitality Management
              Institute, we pride ourselves to having some of the best tutors in
              the country who have been experience culinary and hospitality
              management professionals for years and have also gone ahead to
              develop their own entrepreneurial outfit that is doing quite well
              in the country.
            </p>

            <p class="text-left">
              The aim of bring these seasoned professionals in the industry
              together is for them to power our programs and ensure that
              students exit the school as experienced professionals and experts
              in whatever coursework they choose to undergo.
            </p>

            <p class="text-left">
              It is this approach that makes every alumnus of MOJ catering
              school and hospitality management institute go ahead to become
              leaders in the culinary and hospitality industry.
            </p>
          </div>
        </div>

        <div class="flex flex-wrap">
          <div id="cake" class="w-full px-8 md:px-16 py-8">
            <p class="text-left font-semibold text-xl text-orange-500">
              Our Core Values
            </p>
            <p class="text-left">
              MOJ Catering School and Hospitality Management Institute holds her
              standards on some key core values that defines how we teach, how
              we operate and how we support our students.
            </p>

            <p class="text-left">
              We encourage our students and teachers to share in this core
              values while in the school and outside the school and we try to
              instill and imbibe these core values as best as we can.
            </p>

            <li>
              Excellence: At MOJ Catering school and Hospitality Management
              Institute, we always strive for excellence to be the very best
              that we can be in what we do. Therefore, MOJ invest in recruiting
              the best of the best tutors who are at the apex of their careers,
              so they can teach our students the very best. We also reviewed
              programs from internationally renowned catering schools and then
              tailored our programs to meet our standard of excellence in
              transitioning a complete beginner into an expert caterer, chief,
              hotel manager, baker, events manager, hospitality manager,
              concierge officer and other esteemed disciplines in the catering
              and hospitality management profession.
            </li>

            <li>
              Respect: Respect has always been the best ingredient in the
              culinary and hospitality business and is at the center of MOJ
              catering school and hospitality management institute core values.
              We believe that more than a well-cooked meal is a well-respected
              and honored guest. Hence, we teach our students and teachers the
              act of treating one and other with the utmost respect. We imbibe
              this culture into our students to ensure that they run their
              culinary and hospitality career with respect for their vendors,
              clients and mentors.
            </li>

            <li>
              Professionalism: At primary aim of MOJ catering school and
              hospitality management institute is to raise experienced
              professionals that can deliver on any culinary or hospitality
              management project. While we don’t just teach our students to be
              expert chefs, bakers, event planners, caterers, or hotel managers;
              we teach them to professionals, executing their duties with
              integrity, discipline, and respect.
            </li>

            <li>
              Safety & Hygiene: Finally, safety is paramount for us at MOJ
              catering school and hospitality management institute. We teach our
              student and teachers to imbibe safe conducts when caring out their
              duties. These is inherent in the way they handle equipment’s, the
              way they comport themselves in the kitchen, wearing the proper kit
              and protective clothing while carrying out their duties. We also
              ensure that all our program includes lectures of inculcating a
              proper hygiene culture into our students. This is to ensure that
              they are always clean, their equipment’s and surrounding are
              always clean for them to deliver their culinary duties with proper
              Hygiene.
            </li>
          </div>
        </div>

        <div class="flex flex-wrap">
          <div id="cake" class="w-full px-8 md:px-16 py-8">
            <p class="text-left font-semibold text-xl text-orange-500">
              Our Mantra
            </p>
            <p class="text-left text-italics">
              Raising professionals that will continuously transform the
              culinary and hospitality industry.
            </p>

            <p class="text-left">
              Our Mantra at MOJ catering school and Hospitality Management
              Institute explains in a nutshell what we hope to achieve with you.
              We encourage you to entrust your catering and hospitality
              management career with us as we hope to achieve this fit with you.
              We are the best catering school in Lagos Nigeria situated in the
              heart of the city, Ikeja.
            </p>

            <p class="text-left">
              We will equip you with the skills you need to completely redefine
              what is a great meal, we will offer you with special recipes,
              secret ingredients, tips and tricks on how to stand out and
              deliver your duties professionally while beating the competition.
              We will complete this journey by issuing you with a certificate
              that you can both defend and represent.
            </p>
          </div>
        </div>

        <div class="flex flex-wrap">
          <div id="cake" class="w-full px-8 md:px-16 py-8">
            <p class="text-left font-semibold text-xl text-orange-500">
              Career Opportunities
            </p>
            <p class="text-left">
              We are always in search of people help us in different areas of
              work. Since MOJ catering and hospitality management institute is
              not just a school but an organization that also delivers the best
              of catering and events management services we are interested in
              getting the best people to work with us. If you are interested in
              working with us in any of these capacities listed below, please
              fill out the form below and provide us with your resume and we
              will get back to you as soon as an opportunity is available to
              meets your proficiencies and skills.
            </p>

            <li>Catering Faculties and Tutors</li>

            <li>Events Planning Faculties and Tutors</li>

            <li>Caterers & Chefs</li>

            <li>Food Servers</li>

            <li>Venue Decoration Assistants</li>
            <li>Cleaners</li>
          </div>
        </div>

        <p class="text-lg text-center">Academics</p>
        <div class="flex flex-wrap">
          <div id="cake" class="w-full px-8 md:px-16 py-8">
            <p class="text-left font-semibold text-xl text-orange-500">
              Diploma
            </p>
            <p class="text-left">
              MOJ catering school and hospitality management institute offers
              her students with the opportunity to complete a 6-month diploma
              program in catering, events management, and cake baking. These
              diploma programs have been designed to contain all that you need
              to go from a novice to a professional in catering, events planning
              and cake baking covering all topics from end to end. At the end of
              the six months, you will be awarded with MOJ catering school and
              hospitality management institute diploma certificate.
            </p>
          </div>
        </div>

        <div class="flex flex-wrap">
          <div id="cake" class="w-full px-8 md:px-16 py-8">
            <p class="text-left font-semibold text-xl text-orange-500">
              Certificate Program
            </p>
            <p class="text-left">
              Our certificate programs are more dedicated programs geared at
              making you a professional in any catering or hospitality
              management vocation. We have several certificate courses all
              designed to help you acquire a lot of skills in the shortest
              possible time. Most of our certificate course are between 1 – 3
              Months and at the end of this duration, you will be awarded with
              MOJ catering school and hospitality management institute expert
              certificate.
            </p>
          </div>
        </div>

        <div class="flex flex-wrap">
          <div id="cake" class="w-full px-8 md:px-16 py-8">
            <p class="text-left font-semibold text-xl text-orange-500">
              Special Programs
            </p>
            <p class="text-left">
              Our special programs are either full time or part time programs
              that covers only one month window and are designed to cover just
              one subject area. The program is designed so that the student
              master a subject area in catering and hospitality management in
              the shortest time possible while adhering to the student’s time
              constraints. Our special programs are suitable for working
              professionals or have just a little time to invest but also wish
              to acquire the most skills they can acquire.
            </p>

            <p class="text-left">
              Also, if you are an intending entrepreneur in the catering and
              hospitality business that wished to create a business around a
              specific area of the profession, then our special programs offer
              you the flexibility to pick out the key topics you wish to master
              and focusing on only those subject areas.
            </p>
          </div>
        </div>
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header2.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
  metaInfo: {
    title: "About Us",
  },
};
</script>

<style>
li {
  margin: 0px 10px;
}
</style>
