<template>
  <div class="my-body text-white">
    <main class="boxed__container">
      <HeaderComponent></HeaderComponent>
      <div
        class="flex flex-wrap text-center justify-center md:my-48 items-center p-8"
      >
        <div class="w-full justify-center">
          <div class="md:mx-16">
            <p
              class="main-title text-4xl md:text-6xl uppercase font-bold md:font-bold my-12 md:m-2"
            >
              MOJ INSTITUTE OF CATERING & HOTEL MANAGEMENT
            </p>
            <p>
              <span class="text-xl text-orange-500 leading-none"
                >The Leading Catering School in Nigeria</span
              ><br />
            </p>
          </div>
        </div>
      </div>
      <div></div>
    </main>
  </div>
</template>

<script>
import HeaderComponent from "@/components/Header.vue";

export default {
  components: {
    HeaderComponent,
  },
};
</script>

<style scoped>
img {
  width: 80%;
  margin: auto;
  overflow: hidden;
}
div {
  overflow: hidden;
  color: white !important;
}
.my-body {
  min-height: 50vh;
  background: linear-gradient(
      89.81deg,
      #111111 0.05%,
      rgba(17, 17, 17, 0.65) 41.25%
    ),
    url(https://res.cloudinary.com/dldd8ucby/image/upload/v1687289031/1686930509111.jpg)
      no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
</style>
