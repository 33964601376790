<template>
  <div id="app">
    <Header />
    <main class="boxed__container">
      <p class="text-2xl font-bold mt-8 text-center text-orange-500">
        Contact Us
      </p>
      <p class="text-lg text-center">
        Here are the numerous easy ways you can reach out to us here at MOJ
      </p>

      <div class="p-4 md:mx-8 my-8 text-center flex flex-wrap">
        <div class="w-full md:w-1/3">
          <p class="font-bold tracking-wider m-2">We are open</p>
          <p class="tracking-tighter font-hairline text-sm m-1">
            Monday - Friday: 8am to 5pm
          </p>
          <p class="tracking-tighter font-hairline text-sm m-1">
            Saturdays: 9am to 2pm
          </p>
          <p class="tracking-tighter font-hairline text-sm m-1">
            Public Holidays: On request
          </p>
        </div>

        <div class="w-full md:w-1/3">
          <p class="font-bold tracking-wider m-2">Address</p>
          <p class="tracking-tighter font-hairline text-sm m-1">
            2 Shitta Street, off Adebisi Awosoga Street, No 1 Bus Stop, Oniwaya
            Road
          </p>
        </div>

        <div class="w-full md:w-1/3">
          <p class="font-bold tracking-wider m-2">Contact Numbers</p>
          <p class="tracking-tighter font-hairline text-sm m-1">08034538413</p>
          <p class="tracking-tighter font-hairline text-sm m-1">08084537594</p>
          <p class="tracking-tighter font-hairline text-sm m-1">08039300940</p>
        </div>
      </div>

      <GmapMap
        class="mx-auto my-4"
        :center="{ lat: 6.61251, lng: 3.31695 }"
        :zoom="15"
        map-type-id="terrain"
        style="width: 90%; height: 400px"
      >
        <GmapMarker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          :clickable="true"
          :draggable="true"
          @click="center = m.position"
        />
      </GmapMap>
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header2.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
  metaInfo: {
    title: "Contact Us",
  },
  data() {
    return {
      center: {},
      markers: [
        {
          position: {
            lat: 6.61251,
            lng: 3.31695,
          },
        },
      ],
    };
  },
};
</script>

<style></style>
