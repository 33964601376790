import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Services from "./views/Services.vue";
import Courses from "./views/Courses.vue";
import ContactUs from "./views/Contact.vue";
import About from "./views/About.vue";
import Error from "./views/404.vue";


Vue.use(Router);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/services",
    name: "services",
    component: Services
  },
  {
    path: "/contact",
    name: "contact-us",
    component: ContactUs
  },
  {
    path: "/courses",
    name: "courses",
    component: Courses
  },
  {
    path: "/about",
    name: "about",
    component: About
  },
  {
    path: "*",
    name: "error",
    component: Error
  },
];

const router = new Router({
  mode: 'history',
  routes,
  scrollBehavior: function (to) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
    else {
      return { x: 0, y: 0, };
    }
  }
});

export default router;
