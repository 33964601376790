<template>
  <div id="footer" class="my-footer px-2 md:px-16 py-8">
    <main class="boxed__container">
      <div class="p-4 flex flex-wrap">
        <div class="w-full md:w-1/3">
          <div class="md:p-8">
            <p class="font-semibold text-lg m-2 text-orange-500">
              MOJ Catering
            </p>
          </div>
        </div>
        <div class="w-full md:w-1/3">
          <div class="md:p-8 text-white">
            <router-link to="/" class="m-2">Home</router-link>
            <p class="m-2"><a href="#" to="/about">About</a></p>
            <p class="m-2"><a href="#" to="/courses">Courses</a></p>
            <p class="m-2"><a href="#" to="/services">Our Services</a></p>
            <router-link to="/contact" class="m-2">Contact Us</router-link>
          </div>
        </div>
        <div class="w-full md:w-1/3">
          <div class="md:p-8">
            <a href="https://www.facebook.com/mojcateringschool/">
              <img class="inline m-1" src="../assets/facebook.png" />
            </a>

            <p class="text-left text-white">
              &copy; MOJ Catering Services
              {{ new Date().getFullYear() }}.<br />All rights reserved
            </p>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.my-footer {
  background-color: #141720;
  padding: 12rem auto;
}
.footer-title {
  font-size: 16px;
  line-height: 26px;
  color: #fed67a;
}
.footer-text {
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
}
.footer-other-title {
  font-size: 24px;
  line-height: 26px;
  color: #ffffff;
}
.order-btn {
  background-color: #fed67a;
  color: #835b00;
  text-transform: uppercase;
}
</style>
